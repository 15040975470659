.pane {
  @apply flex flex-col shadow-md rounded-t-md fixed bottom-0 right-5 w-[256px] h-[350px] z-[1000];
  max-height: calc(100vh - 70px);
  transition: 0.05s;

  &--main {
    height: calc(100vh - 70px);

    .pane__header .pane__title {
      font-size: 16px;
    }
  }

  &--minimized {
    height: 31px;
  }

  .search--account {
    border-top: 1px solid hsla(var(--primary-text-color_hsl), 0.2);
    padding: 5px;
  }

  &__header {
    @apply flex items-center py-0 px-2.5 box-border rounded-t-md font-bold bg-primary-600 text-white;
    height: 31px;

    .account__avatar {
      margin-right: 7px;
    }

    .pane__title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;
      height: 100%;
      background: transparent;
      border: 0;
      padding: 0;
      color: #fff;
      font-weight: bold;
      text-align: left;
      font-size: 14px;
    }

    .icon-button {
      opacity: 0.7;
      color: #fff;

      &:hover {
        opacity: 1;
      }

      > div {
        margin-right: -6px;
      }
    }

    .pane__close {
      margin-left: auto;

      .svg-icon {
        width: 18px;
        height: 18px;
        transform: translateY(2px);
      }
    }
  }

  &__content {
    @apply flex flex-1 flex-col overflow-hidden bg-white dark:bg-gray-800;

    > div {
      @apply max-h-full;
    }

    .chat-box {
      @apply flex flex-1 flex-col overflow-hidden;
    }

    .chat-list {
      @apply overflow-y-auto max-h-full;
    }
  }

  .audio-toggle .react-toggle-thumb {
    @apply w-3.5 h-3.5 border border-solid border-primary-400;
  }

  .audio-toggle .react-toggle {
    @apply top-1;
  }

  .audio-toggle .react-toggle-track {
    @apply h-4 w-8 bg-accent-500;
  }

  .audio-toggle .react-toggle-track-check {
    left: 4px;
    bottom: 0;
  }

  .react-toggle--checked .react-toggle-thumb {
    left: 19px;
  }

  .audio-toggle .react-toggle-track-x {
    right: 5px;
    bottom: 0;
  }

  .fa {
    font-size: 14px;
  }
}

.chat-messages {
  overflow-y: scroll;
  flex: 1;
}

.chat-message {
  margin: 14px 10px;
  display: flex;

  &__bubble {
    @apply px-2.5 py-1 rounded-lg bg-primary-50 dark:bg-gray-700;
    max-width: 70%;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    white-space: break-spaces;
    position: relative;

    a {
      color: var(--brand-color--hicontrast);
    }

    &:hover,
    &:focus,
    &:active {
      .chat-message__menu {
        opacity: 1;
        pointer-events: all;
      }
    }

    &--onlyEmoji {
      background: transparent !important;

      img.emojione {
        width: 36px !important;
        height: 36px !important;
      }
    }
  }

  &--me .chat-message__bubble {
    @apply bg-primary-200 dark:bg-primary-900;
    margin-left: auto;
  }

  &--pending .chat-message__bubble {
    opacity: 0.5;
  }

  &__menu {
    position: absolute;
    top: -8px;
    right: -8px;
    height: 20px;
    padding: 1px;
    background: var(--background-color);
    border-radius: 999px;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s;

    .svg-icon {
      height: 20px;
      width: 20px;
    }
  }
}

.chat-list {
  flex: 1;

  &__content {
    height: 100%;
  }

  .empty-column-indicator {
    height: 100%;
    box-sizing: border-box;
    background: transparent;
    align-items: start;
  }

  .account {
    border-bottom: none;
  }

  .account__display-name {
    position: relative;

    .display-name {
      display: flex;

      .hover-ref-wrapper {
        display: flex;
      }

      bdi {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .display-name__account {
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
        display: none;
      }
    }
  }
}

.chat-box {
  &__attachment {
    display: flex;
    align-items: center;
    font-size: 13px;
    padding: 0 10px;
    height: 25px;

    .chat-box__remove-attachment {
      margin-left: auto;

      .icon-button > div {
        display: flex;
        align-items: center;
      }
    }
  }

  &__actions {
    background: var(--foreground-color);
    margin-top: auto;
    padding: 6px;
    position: relative;

    textarea {
      width: 100%;
      height: 100%;
      margin: 0;
      box-sizing: border-box;
      padding: 6px;
      padding-right: 25px;
      background: var(--background-color);
      border: 0;
      border-radius: 6px;
      color: var(--primary-text-color);
      font-size: 15px;
      overflow: hidden;
    }
  }

  &__send {
    .icon-button,
    button {
      @apply absolute right-2.5 w-auto h-auto border-0 p-0 m-0 text-black dark:text-white;
      top: 50%;
      transform: translateY(-50%);

      .svg-icon,
      svg {
        @apply w-[18px] h-[18px];
      }
    }
  }
}

@media (max-width: 630px) {
  .chat-panes {
    display: none;
  }
}

.chatroom__header {
  display: flex;
  margin-left: auto;
  padding-right: 15px;
  overflow: hidden;
  text-decoration: none;

  .account__avatar {
    margin-right: 7px;
  }

  .chatroom__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    height: 100%;
    background: transparent;
    border: 0;
    padding: 0;
    color: var(--primary-text-color);
    font-weight: bold;
    text-align: left;
    font-size: 14px;
  }
}

.chat {
  &__attachment-icon {
    float: right;
  }

  &__last-message {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-height: 19px;

    a {
      color: var(--highlight-text-color);
    }

    &.attachment {
      font-style: italic;
    }
  }
}

.chat-message__media {
  height: 120px;
}

.chat-message .media-gallery {
  height: 100% !important;
  margin: 4px 0 8px;

  .media-gallery__item:not(.media-gallery__item--image) {
    max-width: 100%;
    width: 120px !important;
    height: 100% !important;
  }

  &__preview {
    background-color: transparent;
  }
}

.chat-messages__divider {
  @apply pt-3.5 pb-0.5 text-center uppercase text-black dark:text-white;
  font-size: 13px;
  opacity: 0.8;
}

.floating-link {
  @apply w-full h-full inset-0 absolute z-10;
}
